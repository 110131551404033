import { Link, Route, Routes, useLocation } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { useEffect, Suspense } from "react";

import {
  Layout,
  Main,
  Homes,
  BuyHome,
  RentHome,
  ForConstructors,
  PromotionPlacement,
  AddAdvert,
  AddAdvertFinish,
  CardDetail,
  CabinetMain,
  MyAdverts,
  CabinetSettings,
  CabinetFavourites,
  Map,
  NewBuildings,
  NewBuildingsDetail,
  AddObject,
  Daily,
  UpdateAdvert,
  MyObjects,
  ConstructorPage,
  AuthorPage,
  AddAdvert2,
  AddAdvert3,
  UpdateObject,
  Rules,
} from "./router/router";

import "react-loading-skeleton/dist/skeleton.css";
import "antd/dist/reset.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./App.css";

function App() {
  TopBarProgress.config({
    barColors: {
      0: "#007BFB",
      "1.0": "#007BFB",
    },
  });

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (location.host.includes("www.")) {
      // eslint-disable-next-line no-restricted-globals
      location.href = "https://xona.tj";
    }
  });

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<TopBarProgress />}>
              <Layout />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<TopBarProgress />}>
                <Main />
              </Suspense>
            }
          />
          <Route
            path="homes"
            element={
              <Suspense fallback={<TopBarProgress />}>
                <Homes />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense fallback={<TopBarProgress />}>
                  <BuyHome />
                </Suspense>
              }
            />
            <Route
              path="rent"
              element={
                <Suspense fallback={<TopBarProgress />}>
                  <RentHome />
                </Suspense>
              }
            />
            <Route
              path="daily"
              element={
                <Suspense fallback={<TopBarProgress />}>
                  <Daily />
                </Suspense>
              }
            />

            <Route
              path="newBuildings"
              element={
                <Suspense fallback={<TopBarProgress />}>
                  <NewBuildings />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="forConstructors"
            element={
              <Suspense fallback={<TopBarProgress />}>
                <ForConstructors />
              </Suspense>
            }
          />

          <Route
            path="map"
            element={
              <Suspense fallback={<TopBarProgress />}>
                <Map />
              </Suspense>
            }
          />

          <Route
            path=":advertId/cardDetail"
            element={
              <Suspense fallback={<TopBarProgress />}>
                <CardDetail />
              </Suspense>
            }
          />
          <Route
            path=":advertId/newBuildingDetail"
            element={
              <Suspense fallback={<TopBarProgress />}>
                <NewBuildingsDetail />
              </Suspense>
            }
          />

          <Route
            path=":developerId/constructor"
            element={
              <Suspense fallback={<TopBarProgress />}>
                <ConstructorPage />
              </Suspense>
            }
          />

          <Route
            path=":userId/adverts"
            element={
              <Suspense fallback={<TopBarProgress />}>
                <AuthorPage />
              </Suspense>
            }
          />
          <Route
            path="reklama"
            element={
              <Suspense fallback={<TopBarProgress />}>
                <PromotionPlacement />
              </Suspense>
            }
          />
          <Route
            path="addAdvert"
            element={
              <Suspense fallback={<TopBarProgress />}>
                <AddAdvert />
              </Suspense>
            }
          />
          <Route
            path="addAdvert2"
            element={
              <Suspense fallback={<TopBarProgress />}>
                <AddAdvert2 />
              </Suspense>
            }
          />
          <Route
            path="addAdvert3"
            element={
              <Suspense fallback={<TopBarProgress />}>
                <AddAdvert3 />
              </Suspense>
            }
          />
          <Route
            path=":id/update"
            element={
              <Suspense fallback={<TopBarProgress />}>
                <UpdateAdvert />
              </Suspense>
            }
          />
          <Route
            path=":id/update-object"
            element={
              <Suspense fallback={<TopBarProgress />}>
                <UpdateObject />
              </Suspense>
            }
          />
          <Route
            path="addObject"
            element={
              <Suspense fallback={<TopBarProgress />}>
                <AddObject />
              </Suspense>
            }
          />
          <Route
            path="addAdvertFinish"
            element={
              <Suspense fallback={<TopBarProgress />}>
                <AddAdvertFinish />
              </Suspense>
            }
          />
          <Route
            path="cabinet"
            element={
              <Suspense fallback={<TopBarProgress />}>
                <CabinetMain />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense fallback={<TopBarProgress />}>
                  <MyAdverts />
                </Suspense>
              }
            />
            <Route
              path="settings"
              element={
                <Suspense fallback={<TopBarProgress />}>
                  <CabinetSettings />
                </Suspense>
              }
            />
            <Route
              path="my-objects"
              element={
                <Suspense fallback={<TopBarProgress />}>
                  <MyObjects />
                </Suspense>
              }
            />
            <Route
              path="favorites"
              element={
                <Suspense fallback={<TopBarProgress />}>
                  <CabinetFavourites />
                </Suspense>
              }
            />
          </Route>

          <Route
            path="rules"
            element={
              <Suspense fallback={<TopBarProgress />}>
                <Rules />
              </Suspense>
            }
          />

          <Route
            path="/:id*"
            element={
              <Suspense>
                <div className="my-20 flex justify-center items-center flex-col gap-5">
                  <h2 className="text-[64px] font-[700] text-blue-500">404</h2>
                  <h1>Страница не найден</h1>
                  <Link
                    to="/"
                    className="text-white rounded bg-blue-500 px-4 py-2"
                  >
                    Главная страница
                  </Link>
                </div>
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </>
  );
}

export default App;
