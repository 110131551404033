import { lazy } from "react";

export const Layout = lazy(() => import("../container/layout/Layout"));
export const Main = lazy(() => import("../container/pagesLinks/Main"));
export const Homes = lazy(() => import("../container/pagesLinks/Homes"));
export const BuyHome = lazy(() => import("../container/pagesLinks/Buy"));
export const RentHome = lazy(() => import("../container/pagesLinks/Rent"));
export const Daily = lazy(() => import("../container/pagesLinks/Daily"));
export const ForConstructors = lazy(() =>
  import("../container/pagesLinks/ForConstructors")
);
export const PromotionPlacement = lazy(() =>
  import("../container/pagesLinks/PromotionPlacement")
);
export const AddAdvert = lazy(() =>
  import("../container/pagesLinks/AddAdvert")
);
export const AddAdvert2 = lazy(() =>
  import("../container/pagesLinks/AddAdvert2")
);
export const AddAdvert3 = lazy(() =>
  import("../container/pagesLinks/AddAdvert3")
);
export const AddObject = lazy(() =>
  import("../container/pagesLinks/AddObject")
);
export const CardDetail = lazy(() =>
  import("../container/pagesLinks/OneCardDetail")
);
export const CabinetMain = lazy(() =>
  import("../container/pagesLinks/cabinet/CabinetMain")
);
export const MyAdverts = lazy(() =>
  import("../container/pagesLinks/cabinet/cabinetOutlets/MyAdverts")
);
export const CabinetSettings = lazy(() =>
  import("../container/pagesLinks/cabinet/cabinetOutlets/CabinetSettings")
);
export const CabinetFavourites = lazy(() =>
  import("../container/pagesLinks/cabinet/cabinetOutlets/CabinetFavourites")
);
export const AddAdvertFinish = lazy(() =>
  import("../container/pagesLinks/AddAdvertFinish")
);
export const Map = lazy(() => import("../container/pagesLinks/MapPage"));
export const NewBuildings = lazy(() =>
  import("../container/pagesLinks/NewBuildings")
);
export const NewBuildingsDetail = lazy(() =>
  import("../container/pagesLinks/detail/DetailPage2")
);
export const UpdateAdvert = lazy(() =>
  import("../container/pagesLinks/UpdateAdvert")
);
export const MyObjects = lazy(() =>
  import("../container/pagesLinks/cabinet/cabinetOutlets/MyObjects")
);
export const ConstructorPage = lazy(() =>
  import("../container/pagesLinks/ConstructorPage")
);
export const AuthorPage = lazy(() =>
  import("../container/pagesLinks/AuthorPage")
);
export const UpdateObject = lazy(() =>
  import("../container/pagesLinks/UpdateObject")
);
export const Rules = lazy(() => import("../container/pagesLinks/Rules"));
